import * as React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { getLanguageOptions, SHORT_LANGUAGE_TRANSLATIONS_MAP } from './LanguageSwitcher.utils';
import dynamic from 'next/dynamic';
import { Globe } from 'lucide-react';
import analytics, { ANALYTIC_EVENTS } from 'utils/firebase/analytics';

const DropdownMenu = dynamic(() => import('components/DropdownMenu/DropdownMenu'));

type Props = {
  btnClass?: string;
  contentClass?: string;
  longLabel?: boolean;
  dropdownItemClass?: string;
};

export default function LanguageSwitcher({
  btnClass,
  contentClass,
  longLabel,
  dropdownItemClass
}: Props) {
  const router = useRouter();
  const { locale: activeLocale } = router;
  const languageOptions = getLanguageOptions();

  const handleChangeLanguage = (locale: string | number) => {
    router.replace(router.asPath, router.asPath, {
      locale: locale as string,
      scroll: false
    });
    analytics.logEvent(ANALYTIC_EVENTS.LANGUAGE_CHANGE, { locale, origin: router.pathname });
  };

  const selected = languageOptions.find(lo => lo.value === activeLocale) || languageOptions[0];

  const renderItem = option => (
    <span className={clsx(selected?.value === option.value && 'font-semibold')}>
      {option.label}
    </span>
  );

  return (
    <DropdownMenu
      ariaLabel="Language switcher"
      onChange={handleChangeLanguage}
      className="LanguageSwitcher"
      options={languageOptions}
      keyExtractor={opt => opt.value}
      renderItem={renderItem}
      align="start"
      noIcon={!longLabel}
      contentClassName={contentClass}
      {...(btnClass && { btnProps: { className: btnClass } })}
      dropdownItemClassName={dropdownItemClass}
    >
      {longLabel && <Globe size={16} strokeWidth={1.5} />}
      <span className={clsx('leading-5 whitespace-nowrap', longLabel ? 'text-caption' : 'text-sm')}>
        {longLabel ? selected.label : SHORT_LANGUAGE_TRANSLATIONS_MAP[selected.value]}
      </span>
      {!longLabel && <Globe size={18} strokeWidth={1.5} />}
    </DropdownMenu>
  );
}
