import * as React from 'react';
import { useGetEnrolledMultiFactorData } from 'utils/firebase/hooks';
import { useAuth } from 'contexts/authUser/store';
import { routes } from 'utils/routes';
import useMatchWithCurrentPathname from 'hooks/useMatchWithCurrentPathname';
import { useTranslation } from 'next-i18next';
import ResendAccountConfirmationEmailButton from 'components/Button/ResendAccountConfirmationEmailButton';
import Button from 'components/Button';
import Link from 'components/Link';
import { useRouter } from 'next/router';
import { X } from 'lucide-react';
import { getCookie, setCookie } from 'cookies-next';
import { ECookieKeys } from 'utils/cookie';
import { getSecondsUntil } from 'utils/time';

interface HeaderBannerData {
  title: string;
  action: string;
  button: React.ReactNode;
  closeKey?: ECookieKeys;
}

interface Props {
  showNavMenu: boolean;
  onClose: () => void;
}

const HeaderBanner = React.forwardRef(
  ({ showNavMenu, onClose }: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const factors = useGetEnrolledMultiFactorData();
    const { authUser } = useAuth();
    const { t } = useTranslation();
    const matchWithCurrentPathname = useMatchWithCurrentPathname();
    const router = useRouter();
    const [data, setData] = React.useState<null | HeaderBannerData>(null);

    const isAuthRoutes = React.useMemo(
      () => Object.values(routes.auth).some(route => matchWithCurrentPathname(route())),
      [matchWithCurrentPathname]
    );

    const showConfirmAccountBanner =
      !!authUser && !authUser?.emailVerified && !isAuthRoutes && !showNavMenu;

    const show2FABanner =
      !!authUser &&
      !factors?.length &&
      !isAuthRoutes &&
      !showNavMenu &&
      !getCookie(ECookieKeys.CONFIGURE_2FA_BANNER);

    React.useEffect(() => {
      if (showConfirmAccountBanner) {
        setData({
          title: t('auth.confirmAccount.banner.instruction'),
          action: t('auth.confirmAccount.banner.action'),
          button: (
            <ResendAccountConfirmationEmailButton className="underline !text-sm ml-[.5ch] !text-info-main text-caption md:text-body1 !font-semibold" />
          )
        });
      } else if (show2FABanner) {
        setData({
          title: t('auth.configure2fa.banner.instruction'),
          action: t('auth.configure2fa.banner.action'),
          closeKey: ECookieKeys.CONFIGURE_2FA_BANNER,
          button: (
            <Link
              href={{
                pathname: routes.account.information.index(),
                query: { configure2FA: true, ...router.query }
              }}
              passHref
              legacyBehavior
            >
              <Button
                className="underline ml-[.5ch]  !text-sm !text-info-main text-caption md:text-body1 !font-semibold"
                variant="link"
                size="custom"
              >
                {t('auth.configure2fa.banner.accountSettings')}
              </Button>
            </Link>
          )
        });
      } else {
        setData(null);
      }
    }, [showConfirmAccountBanner, show2FABanner, t, router.query]);

    // Show banner after 7 days
    const handleClose = (closeKey?: ECookieKeys) => {
      if (closeKey) {
        const date = new Date();
        date.setDate(date.getDate() + 7);
        setCookie(closeKey, true, {
          maxAge: getSecondsUntil(date)
        });
        setData(null);
        onClose();
      }
    };

    if (!data) return null;
    return (
      <div
        ref={ref}
        className="relative z-[100] bg-info-light text-info-main px-6 py-3 text-center border-b-[1.5px] border-b-info-main text-caption md:text-body1"
      >
        <div className="flex items-center justify-center align-middle flex-col md:flex-row">
          <span className="font-semibold text-sm">{data.title}</span>
          <span className="ml-1 mr-2 text-sm">{data.action}</span>
          {data.button}
        </div>

        {data.closeKey && (
          <Button
            variant="custom"
            className="absolute right-0 md:right-1 top-0 md:bottom-0"
            onClick={() => handleClose(data.closeKey)}
          >
            <X size={16} />
          </Button>
        )}
      </div>
    );
  }
);

export default HeaderBanner;
