import * as React from 'react';
import * as Popover from '@radix-ui/react-popover';
import Button from 'components/Button';
import Link from 'components/Link';
import { useShoppingCart } from 'contexts/shoppingCart';
import { ShoppingCart } from 'lucide-react';
import MiniCartContent from './MiniCartContent';
import EmptyMiniCart from './EmptyMiniCart';
import { routes } from 'utils/routes';
import { ICartState } from '@poinz/api';
import { useMiniCart } from 'contexts/miniCart';
import clsx from 'clsx';
import { useIsHeaderTransparent } from 'components/Header/Header.utils';
import { getNumberOfTotalCartItems } from 'utils/shoppingCart';
import analytics, { ANALYTIC_EVENTS, formatAnalyticItems } from 'utils/firebase/analytics';
import { useRouter } from 'next/router';
import { useIsScreenLarger } from 'utils/screen';

export interface Props {
  actionButtonCallback: () => void;
}

const MiniCart: React.FC<Props> = ({ actionButtonCallback }) => {
  const { cart } = useShoppingCart();
  const { isMiniCartOpen, setIsMiniCartOpen } = useMiniCart();
  const isTransparent = useIsHeaderTransparent();
  const { pathname } = useRouter();
  const isLg = useIsScreenLarger();

  const cartRoute = React.useMemo(
    () =>
      cart?.state === ICartState.SHOPPING ? routes.checkout.overview() : routes.checkout.purchase(),
    [cart?.state]
  );

  const totalCartItems = getNumberOfTotalCartItems(cart?.items);

  const onActionButtonClick = () => {
    setIsMiniCartOpen(false);
    actionButtonCallback();
    if (cartRoute === routes.checkout.overview()) {
      analytics.logEvent(ANALYTIC_EVENTS.VIEW_CART, {
        currency: 'CHF',
        value: cart?.finalPrice,
        items: formatAnalyticItems(cart?.items)
      });
    } else if (cartRoute === routes.checkout.purchase()) {
      analytics.logEvent(ANALYTIC_EVENTS.BEGIN_CHECKOUT, {
        currency: 'CHF',
        value: cart?.finalPrice,
        quantity: totalCartItems,
        items: formatAnalyticItems(cart?.items)
      });
    }
  };

  const handleToggleMiniCart = open => {
    setIsMiniCartOpen(open);
    if (open) {
      analytics.logEvent(ANALYTIC_EVENTS.MINI_CART_OPEN, { origin: pathname });
    }
  };

  return isLg ? (
    <div className="relative hidden md:block">
      <Popover.Root modal={true} open={isMiniCartOpen} onOpenChange={handleToggleMiniCart}>
        <Popover.Anchor asChild>
          <Button
            className={clsx('flex gap-2', {
              ['!bg-white !text-neutral-900 hover:!bg-neutral-100']: isTransparent
            })}
            size="badge"
            variant={totalCartItems ? 'secondary-badge' : 'primary-badge'}
            onClick={() => setIsMiniCartOpen(open => !open)}
          >
            <ShoppingCart size={18} strokeWidth={1.5} />
            <span>{totalCartItems}</span>
          </Button>
        </Popover.Anchor>
        <Popover.Content asChild sideOffset={5} align="end">
          <div className="bg-white border border-gray-900 px-9 py-8 rounded-3xl max-h-[70vh] min-w-[23rem] max-w-[23rem] overflow-y-auto z-20 no-scrollbar">
            {cart?.items.length ? (
              <MiniCartContent
                cart={cart}
                totalCartItems={totalCartItems}
                handleActionButtonClick={onActionButtonClick}
                cartRoute={cartRoute}
              />
            ) : (
              <EmptyMiniCart handleActionButtonClick={onActionButtonClick} />
            )}
          </div>
        </Popover.Content>
      </Popover.Root>
    </div>
  ) : (
    <Link
      href={{
        pathname: cartRoute
      }}
      className="md:hidden"
    >
      <Button
        onClick={onActionButtonClick}
        className={clsx('flex gap-2', {
          ['!bg-white !text-neutral-900 hover:!bg-neutral-100']: isTransparent
        })}
        size="badge"
        variant={totalCartItems ? 'secondary-badge' : 'primary-badge'}
      >
        <ShoppingCart size={18} strokeWidth={1.5} />
        <span>{totalCartItems}</span>
      </Button>
    </Link>
  );
};

export default MiniCart;
