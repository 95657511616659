import * as React from 'react';
import Button from 'components/Button';
import UserMenuDialog from './UserMenuDialog';
import Paragraph from 'components/text/Paragraph';
import { User } from 'lucide-react';
import { useAuth } from 'contexts/authUser/store';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { USER_MENU_LINKS } from 'utils/links';
import { useLoyalty } from 'contexts/loyalty';
import { routes } from 'utils/routes';
import { formatNumber } from 'utils/number';
import { useIsHeaderTransparent } from 'components/Header/Header.utils';
import clsx from 'clsx';
import { Crisp } from 'crisp-sdk-web';
import analytics, { ANALYTIC_EVENTS } from 'utils/firebase/analytics';
import { useRouter } from 'next/router';
import { useGetTotalBalance } from 'containers/UserAreaCashback/BalanceAndActivity/Balance/Balance.utils';
interface Props {
  onOpen?: () => void;
}
const UserMenu: React.FC<Props> = ({ onOpen }) => {
  const { t } = useTranslation();
  const { user, signOut } = useAuth();
  const { avatar, firstName, email, lastName } = user || {};
  const [isOpen, setIsOpen] = React.useState(false);
  const { userBalance } = useLoyalty();
  const { pathname } = useRouter();
  const totalBalance = useGetTotalBalance();

  const toggleDrawer = () => {
    if (onOpen) {
      onOpen();
    }
    setIsOpen(false);
  };

  const handleLinkClick = (analyticEvent: ANALYTIC_EVENTS) => {
    toggleDrawer();
    analytics.logEvent(analyticEvent, { origin: pathname });
  };

  const handleSignOut = () => {
    toggleDrawer();
    signOut();
  };

  const handleOpenChange = (_open: boolean) => {
    if (onOpen) {
      onOpen();
    }
    setIsOpen(_open);
  };

  const handleSupportClick = () => {
    Crisp.chat.open();
  };

  React.useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  const handleOpenDrawer = React.useCallback(() => {
    setIsOpen(true);
    analytics.logEvent(ANALYTIC_EVENTS.USER_DRAWER_OPEN, { origin: pathname });
  }, [pathname]);

  const isTransparent = useIsHeaderTransparent();
  return (
    <>
      <Button
        className={clsx(
          '!px-2',
          isTransparent
            ? '!bg-white/20 !text-white hover:!bg-white/40 focus:!bg-white/40 backdrop-blur-[6px]'
            : '!bg-neutral-100 hover:!bg-neutral-50 !text-neutral-900'
        )}
        size="badge"
        variant="ghost"
        onClick={handleOpenDrawer}
        aria-label="User menu"
      >
        <User size={20} strokeWidth={1.5} />
      </Button>

      <UserMenuDialog open={isOpen} onOpenChange={handleOpenChange}>
        <div className="flex flex-col h-full overflow-auto">
          <div className="pt-14 pb-9 px-9 border-b border-b-neutral-900 dark:border-b-white">
            <div className="flex gap-6 mb-9">
              <div className="relative">
                {avatar ? (
                  <Image
                    draggable={false}
                    className="rounded-full object-fill"
                    src={avatar?.sourceUrl}
                    alt={`${firstName} profile photo`}
                    width={80}
                    height={80}
                  />
                ) : (
                  <div className="w-20 h-20 flex items-center justify-center bg-neutral-200 rounded-full text-2xl">
                    <Paragraph className="uppercase dark:!text-neutral-900">
                      {firstName?.[0] || email?.[0]}
                    </Paragraph>
                  </div>
                )}
              </div>
              <div>
                {firstName && lastName && (
                  <div className="text-sm font-normal max-w-[220px] pr-4 text-wrap">{`${firstName} ${lastName}`}</div>
                )}
                <Link
                  className="underline underline-offset-2 text-primary hover:text-primary-lighter text-button font-normal cursor-pointer inline-block"
                  href={routes.account.information.index()}
                  onClick={() => handleLinkClick(ANALYTIC_EVENTS.ACCOUNT_INFORMATION)}
                >
                  {t('links.general.accountSettings')}
                </Link>
              </div>
            </div>
            {userBalance && (
              <>
                <Paragraph className="text-button font-semibold mb-1">
                  {t('userArea.loyaltyBalanceNumber')}
                </Paragraph>
                <Paragraph className="text-sm mb-4">{userBalance.swissLoyaltyId}</Paragraph>
                <Paragraph className="text-button font-semibold mb-1">
                  {t('userArea.loyaltyBalanceTotal')}
                </Paragraph>
                <Paragraph className="text-heading-md">
                  {formatNumber({ value: totalBalance, showCurrency: true })}
                </Paragraph>
              </>
            )}
            {/* <Link
              className="underline underline-offset-2 text-primary hover:text-primary-lighter text-button font-normal inline-block"
              href={'/'}
              onClick={handleLinkClick}
            >
              {t('links.general.redeem')}
            </Link> */}
          </div>
          <div className="p-9 flex flex-col h-full">
            {USER_MENU_LINKS.map(({ label, link, analyticEvent }) => (
              <Link
                key={label}
                className="text-button font-normal hover:text-neutral-500 cursor-pointer py-[6px] block"
                href={link}
                onClick={() => handleLinkClick(analyticEvent)}
              >
                {t(label)}
              </Link>
            ))}
            <Button
              className="self-start underline underline-offset-2 !text-primary hover:!text-primary-lighter text-button font-normal cursosr-pointer block py-2 mt-4"
              onClick={handleSignOut}
              variant="link"
            >
              {t('general.form.logOut')}
            </Button>
            <Button className="mt-auto self-start" onClick={handleSupportClick} variant="primary">
              {t('general.getSupport')}
            </Button>
          </div>
        </div>
      </UserMenuDialog>
    </>
  );
};

export default UserMenu;
