import { Language } from 'types/lang';

export const LANGUAGE_TRANSLATIONS_MAP = {
  [Language.DE]: 'Deutsch',
  [Language.EN]: 'English',
  [Language.FR]: 'Français',
  [Language.IT]: 'Italiano'
};

export const SHORT_LANGUAGE_TRANSLATIONS_MAP = {
  [Language.DE]: 'DE',
  [Language.EN]: 'EN',
  [Language.FR]: 'FR',
  [Language.IT]: 'IT'
};

export const getLanguageOptions = () => {
  const supportedLanguages = Object.values(Language);
  return supportedLanguages.map(language => {
    return {
      label: LANGUAGE_TRANSLATIONS_MAP[language],
      value: language,
      image: {
        src: `/assets/languages/${language}.svg`,
        alt: `general.${language}`,
        size: 50
      }
    };
  });
};
