import * as React from 'react';
import Link from 'next/link';
import Button from 'components/Button/Button';
import { routes } from 'utils/routes';
import { useTranslation } from 'next-i18next';

interface Props {
  handleActionButtonClick: () => void;
}

const EmptyMiniCart: React.FC<Props> = ({ handleActionButtonClick }) => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <div className="text-heading-mini text-gray-900">{t('cart.empty.title')}</div>
      <div className="text-body1 text-gray-500">{t('cart.empty.discover')}</div>
      <Link
        href={{
          pathname: routes.shop.index()
        }}
        passHref
        legacyBehavior
      >
        <Button
          className="mt-6"
          variant="secondary"
          size="default"
          onClick={handleActionButtonClick}
        >
          {t('general.shop')}
        </Button>
      </Link>
    </div>
  );
};

export default EmptyMiniCart;
