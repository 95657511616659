import { useState, useRef, DependencyList, useLayoutEffect, useEffect } from 'react';
import { isClientSide } from 'utils/general';

interface IPosition {
  x: number;
  y: number;
}

interface IScrollProps {
  prevPos: IPosition;
  currPos: IPosition;
}

const zeroPosition = { x: 0, y: 0 };

const getClientRect = (element?: HTMLElement) => element?.getBoundingClientRect();

export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const getScrollPosition = () => {
  if (!isClientSide()) {
    return zeroPosition;
  }

  const targetPosition = getClientRect(document.body);

  // TODO: consider removing x axis and renaming zeroPozition into someting like SCREEN_TOP
  return !targetPosition ? zeroPosition : { x: targetPosition.left, y: targetPosition.top };
};

export const useScrollPosition = (
  effect: (props: IScrollProps) => void,
  deps: DependencyList = [],
  wait: number = 200
): void => {
  const position = useRef(getScrollPosition());

  let throttleTimeout: number | null = null;

  const callBack = () => {
    const currPos = getScrollPosition();
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useIsomorphicLayoutEffect(() => {
    if (!isClientSide()) {
      return;
    }

    const handleScroll = () => {
      if (throttleTimeout === null) {
        throttleTimeout = window.setTimeout(callBack, wait);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);

      if (throttleTimeout) {
        clearTimeout(throttleTimeout);
      }
    };
  }, deps);
};

const useScrollDirection = () => {
  const [towardsTop, setTowardsTop] = useState(true);
  const [isOnTop, setIsOnTop] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const scrollingTowardsTop = currPos.y > prevPos.y || currPos.y >= 0;
      if (scrollingTowardsTop !== towardsTop) {
        setTowardsTop(scrollingTowardsTop);
      }
      if (currPos.y === 0) {
        setIsOnTop(true);
      } else {
        setIsOnTop(false);
      }
    },
    [towardsTop]
  );

  return {
    towardsTop,
    isOnTop
  };
};

export default useScrollDirection;
