import * as React from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import { DEFAULT_SHOP_IMAGE_SIZE, SHOP_IMAGE_PLACEHOLDER, scaledImageLoader } from 'utils/image';
import { ICartItem, ICashbackType } from '@poinz/api';
import { useTranslation } from 'next-i18next';
import { formatNumber } from 'utils/number';
import { formatCashbackValue } from 'utils/parse';
import { ITEM_TYPE_KEYS_MAP } from 'utils/shoppingCart';

const MiniCartItem: React.FC<ICartItem> = ({
  id,
  businessProfile,
  picture,
  name,
  itemType,
  cashback,
  quantity,
  price,
  originalPrice
}) => {
  const { t } = useTranslation();

  return (
    <div key={id} className="flex gap-4 mb-4">
      <div className="w-16 self-start shrink-0 overflow-hidden">
        <Image
          className="object-cover w-full h-full !relative"
          src={businessProfile?.logo || picture?.sourceUrl || SHOP_IMAGE_PLACEHOLDER}
          width={picture?.width || DEFAULT_SHOP_IMAGE_SIZE}
          height={picture?.height || DEFAULT_SHOP_IMAGE_SIZE}
          alt={name}
          loader={scaledImageLoader()}
        />
      </div>
      <div className="flex flex-col gap-[0.125rem] text-gray-900">
        <p className="text-caption font-semibold">{businessProfile?.name || name}</p>
        <p className="text-caption capitalize">{`${t(ITEM_TYPE_KEYS_MAP[itemType])}: ${name}`}</p>
        {!!cashback && (
          <p className="text-caption">
            {t('cart.cashback', {
              percentage: formatCashbackValue(cashback, ICashbackType.PERCENTAGE, 0)
            })}
          </p>
        )}
        <p className="text-caption">{t('cart.itemsQuantity', { quantity })}</p>
        <div className="flex gap-1 mt-[6px]">
          {originalPrice && (
            <p className="text-caption  line-through">
              {t('cart.totalPrice', { price: formatNumber({ value: originalPrice }) })}
            </p>
          )}
          <p
            className={clsx('text-caption', {
              ['text-primary font-semibold']: !!originalPrice
            })}
          >
            {t('cart.totalPrice', { price: formatNumber({ value: price }) })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MiniCartItem;
