import * as React from 'react';
import clsx from 'clsx';
import Button from 'components/Button';
import { useTranslation } from 'next-i18next';
import { ChevronDown } from 'lucide-react';
import useScrollDirection from 'hooks/useScroll';
import { useIsHeaderTransparent } from 'components/Header/Header.utils';
import analytics, { ANALYTIC_EVENTS } from 'utils/firebase/analytics';
import { useRouter } from 'next/router';

interface Props {
  label: string;
  section?: string;
  analyticEvent: ANALYTIC_EVENTS;
  items?: {
    label: string;
    link: string;
  }[];
  link?: string;
  handleLinkClick: () => void;
  small?: boolean;
}

const NavigationItem: React.FC<Props> = ({
  section,
  label,
  items,
  link,
  handleLinkClick,
  analyticEvent,
  small = false
}) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { towardsTop } = useScrollDirection();
  const isSection = !!items?.length;
  const router = useRouter();
  const isTransparent = useIsHeaderTransparent();

  React.useEffect(() => {
    if (isExpanded && !towardsTop) {
      setIsExpanded(false);
    }
  }, [isExpanded, towardsTop]);

  const handleLinkClickAndLogEvent = () => {
    handleLinkClick();
    if (analyticEvent) {
      analytics.logEvent(analyticEvent, { origin: router.pathname });
    }
  };

  const handleMouseEnter = () => {
    if (!small) {
      isSection && setIsExpanded(true);
    }
  };
  const handleMouseLeave = () => {
    if (!small) {
      isSection && setIsExpanded(false);
    }
  };
  const handleSectionClick = () => setIsExpanded(exp => !exp);

  const handleSectionLinkClick = () => {
    handleLinkClickAndLogEvent();
    setIsExpanded(false);
  };

  const getHref = React.useCallback(() => {
    // large screen link
    if (!small && link) {
      return link;
    }

    if (small && !items?.length && link) {
      return link;
    }
  }, [items?.length, link, small]);

  const isSelected = pathname === link;

  return (
    <li
      className="relative lg:py-2 w-full lg:w-auto"
      key={section}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        className={clsx(
          'flex text-button lg:text-center items-center justify-between w-full lg:w-auto lg:default-button px-6 lg:px-3 py-4 lg:py-2 lg:hover:bg-neutral-50 lg:focus:bg-neutral-50 text-neutral-900 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 dark:text-white !font-normal',
          {
            ['py-6']: !isSection,
            ['lg:!text-white lg:hover:!bg-gray-50/20 lg:focus:!bg-gray-50/20 bg-neutral-900 lg:bg-inherit text-white lg:text-inherit']:
              isTransparent,
            ['lg:!bg-white/20 lg:backdrop-blur-[6px]']: isTransparent && (isSelected || isExpanded),
            ['lg:!bg-neutral-50']: !isTransparent && (isSelected || isExpanded)
          }
        )}
        variant="custom"
        size="custom"
        href={getHref()}
        onClick={isSection ? handleSectionClick : handleLinkClickAndLogEvent}
      >
        <span>{t(label)}</span>
        {isSection && (
          <ChevronDown
            className={clsx('lg:hidden transition-transform duration-300 m-2', {
              ['rotate-180']: isExpanded
            })}
            size={18}
          />
        )}
      </Button>
      {isSection && isExpanded && (
        <ul
          className={clsx(
            'lg:absolute lg:flex flex-col gap-2 w-full lg:w-[max-content] lg:top-12 bg-neutral-100 dark:bg-neutral-700 lg:bg-white lg:border lg:border-neutral-900 lg:dark:border-white lg:rounded-2xl lg:p-6 py-3',
            {
              ['lg:!bg-white/20 lg:!border-white lg:backdrop-blur-[6px]']: isTransparent
            }
          )}
        >
          {items.map(({ label, link }) => (
            <li key={label} className="w-full lg:leading-5">
              <Button
                className={clsx(
                  'text-button !font-normal py-4 block px-6 lg:p-0 lg:default-button text-neutral-900 dark:text-white hover:!text-neutral-500 dark:hover:!text-neutral-400 focus:!ring-0 focus:!ring-offset-0 focus-visible:!ring-0 focus:text-neutral-500 active:!ring-0',
                  { ['lg:text-white hover:lg:!text-neutral-200']: isTransparent }
                )}
                variant="custom"
                size="custom"
                href={link}
                onClick={handleSectionLinkClick}
              >
                {t(label)}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default NavigationItem;
