import { MultiFactorInfo } from 'firebase/auth';
import * as React from 'react';
import { multiFactor } from 'firebase/auth';
import { AUTH } from '.';

export const useGetEnrolledMultiFactorData = () => {
  const [mfaFactors, setMFAFactors] = React.useState<MultiFactorInfo[] | null>(null);

  const getEnrolledFactors = React.useCallback(() => {
    if (AUTH.currentUser) {
      setMFAFactors(multiFactor(AUTH.currentUser).enrolledFactors);
    } else {
      setMFAFactors(null);
    }
  }, []);

  React.useEffect(() => {
    getEnrolledFactors();
  }, [getEnrolledFactors]);

  return mfaFactors;
};
