import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { useAuth } from 'contexts/authUser/store';
import Button from 'components/Button';
import { useToast } from 'components/Toast';
interface Props {
  className?: string;
}
const ResendAccountConfirmationEmailButton: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { sendEmailVerification } = useAuth();
  const { toast } = useToast();

  const resendEmailConfirmation = React.useCallback(async () => {
    try {
      await sendEmailVerification();
      toast({
        title: t('auth.confirmAccount.emailSent') as string,
        variant: 'success'
      });
    } catch (error: any) {
      toast({
        title: error.message || t('api.error'),
        variant: 'error'
      });
    }
  }, [sendEmailVerification, t, toast]);
  return (
    <Button className={className} variant="link" size="custom" onClick={resendEmailConfirmation}>
      {t('auth.confirmAccount.resendConfirmationMail')}
    </Button>
  );
};

export default ResendAccountConfirmationEmailButton;
