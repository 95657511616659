import * as React from 'react';
import Link from 'next/link';
import Button from 'components/Button/Button';
import { routes } from 'utils/routes';
import MiniCartItem from './MiniCartItem';
import { IShoppingCart } from '@poinz/api';
import { useTranslation } from 'next-i18next';
import { formatNumber } from 'utils/number';

interface Props {
  cart: IShoppingCart;
  handleActionButtonClick: () => void;
  cartRoute: string;
  totalCartItems: number;
}
const MiniCartContent: React.FC<Props> = ({
  cart,
  handleActionButtonClick,
  cartRoute,
  totalCartItems
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-heading-mini">{t('general.cart')}</div>
      <div className="text-body1 text-gray-500 mb-8">
        {t('cart.itemsInCart', {
          count: totalCartItems
        })}
      </div>
      {cart.items.map(item => (
        <MiniCartItem key={item.id} {...item} />
      ))}
      <div className="my-8 text-gray-900 font-semibold text-sm flex justify-between">
        <p>{t('cart.total')}</p>
        <p>{t('cart.totalPrice', { price: formatNumber({ value: cart.totalPrice }) })}</p>
      </div>
      <Link
        href={{
          pathname: cartRoute
        }}
        passHref
        legacyBehavior
      >
        <Button
          className="w-full sticky bottom-0"
          variant="secondary"
          onClick={handleActionButtonClick}
        >
          {t('cart.view')}
        </Button>
      </Link>
    </>
  );
};

export default MiniCartContent;
