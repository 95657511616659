import clsx from 'clsx';
import { Search, X } from 'lucide-react';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { useGlobalSearch } from 'hooks/useGlobalSearch';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { routes } from 'utils/routes';
import GlobalSearchItemsHeader from './GlobalSearchItemsHeader';
import Button from 'components/Button';
import { useIsHeaderTransparent } from 'components/Header/Header.utils';
import dynamic from 'next/dynamic';
import analytics, { ANALYTIC_EVENTS } from 'utils/firebase/analytics';

const GlobalSearchMobileDialog = dynamic(() => import('./GlobalSearchMobileDialog'));

interface Props {
  className?: string;
  onOpenChange: (isOpen: boolean) => void;
}

const GlobalSearchHeader: React.FC<Props> = ({ className, onOpenChange }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const { data, getData } = useGlobalSearch();
  const [isMobileDialogOpen, setIsMobileDialogOpen] = React.useState(false);
  const router = useRouter();
  const isTransparent = useIsHeaderTransparent();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const getDataDebounced = debounce(async val => {
    await getData(val);
    analytics.logEvent(ANALYTIC_EVENTS.GLOBAL_SEARCH, { search: val });
  }, 300);

  const onChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setValue(val);
      getDataDebounced(val);
    },
    [getDataDebounced]
  );

  const handleOpenChange = React.useCallback(
    (_open: boolean) => {
      setIsOpen(_open);
      onOpenChange(_open);
    },
    [onOpenChange]
  );

  const handleClearSearch = React.useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      setValue('');
      getDataDebounced('');
    },
    [getDataDebounced]
  );

  const handleKeyPress = React.useCallback(
    e => {
      // on Enter go to shop page
      if (e.keyCode === 13 && !!value) {
        handleOpenChange(false);
        setValue('');
        getDataDebounced('');
        router.push({ pathname: routes.shop.index(), query: { query: value } });
      }
      // on ESC close the search
      if (e.keyCode === 27) {
        handleOpenChange(false);
      }
    },
    [router, value, getDataDebounced, handleOpenChange]
  );

  const toggleSearch = React.useCallback(() => {
    setIsMobileDialogOpen(!isMobileDialogOpen);
  }, [isMobileDialogOpen]);

  const handleSearchIconClick = React.useCallback(() => {
    if (!isOpen) {
      handleOpenChange(true);
      inputRef.current?.focus();
    }
  }, [isOpen, handleOpenChange]);

  return (
    <>
      {/* desktop global search */}
      <div
        className={clsx(
          'GlobalSearchHeader hidden md:flex relative w-[80%] md:w-[60%]',
          className,
          isOpen ? '' : '!max-w-9 h-9 rounded-[100px]',
          {
            ['hover:!bg-neutral-50']: !isOpen && !isTransparent,
            ['hover:!bg-gray-50/20']: !isOpen && isTransparent
          }
        )}
      >
        <input
          ref={inputRef}
          value={value}
          onChange={onChange}
          onFocus={() => handleOpenChange(true)}
          onBlur={() => handleOpenChange(false)}
          onKeyDown={handleKeyPress}
          aria-label="shop search"
          className={clsx(
            'h-9 flex-1 ml-auto w-full md:px-4 py-2 rounded-[100px] outline-none text-sm text-ellipsis hover:cursor-pointer',
            {
              ['bg-neutral-100 hover:bg-neutral-50 text-neutral-900 placeholder:text-neutral-500']:
                !isTransparent && !isOpen,
              ['!border-neutral-900 border-[1.5px]']: isOpen && !isTransparent,
              ['!bg-white/20 !hover:bg-white/40 backdrop-blur-[6px]']: !isOpen && isTransparent,
              ['h-9 !pl-5 ']: !isOpen,
              ['!px-9']: isOpen
            }
          )}
          placeholder={t('globalSearch.searchPlaceholder') as string}
        />
        <Search
          className={clsx(
            'absolute text-white z-20 cursor-pointer bg-transparent',
            isOpen ? 'top-[11px] left-4' : 'top-[11px] left-[11px]',
            { ['!text-neutral-900']: isOpen || (!isOpen && !isTransparent) }
          )}
          strokeWidth={2}
          onClick={handleSearchIconClick}
          size={15}
        />
        {value && (
          <X
            className={clsx(
              'absolute top-[11px] right-4 z-40 text-neutral-900 cursor-pointer',
              isOpen ? '!text-neutral-900' : 'opacity-0 !-z-10'
            )}
            strokeWidth={2}
            size={15}
            onMouseDown={handleClearSearch}
          />
        )}
        <GlobalSearchItemsHeader
          value={value}
          items={data}
          isOpen={isOpen}
          setIsOpen={handleOpenChange}
        />
      </div>

      {/* mobile global search  */}
      <div className={clsx('GlobalSearchHeaderSmall md:hidden', className)}>
        <Button
          className={clsx(
            '!p-[10px]',
            isTransparent
              ? 'bg-white/20 hover:bg-white/40 backdrop-blur-[6px]'
              : 'bg-neutral-100 hover:bg-neutral-50'
          )}
          size="badge"
          onClick={toggleSearch}
        >
          <Search className={clsx({ ['text-white']: isTransparent })} strokeWidth={2} size={15} />
        </Button>
        <GlobalSearchMobileDialog open={isMobileDialogOpen} onOpenChange={setIsMobileDialogOpen} />
      </div>
    </>
  );
};

export default GlobalSearchHeader;
