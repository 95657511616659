import * as React from 'react';
import { useHeaderItems } from 'utils/links';
import NavigationItem from './NavigationItem';
import clsx from 'clsx';
import { useIsHeaderTransparent } from 'components/Header/Header.utils';
import { ANALYTIC_EVENTS } from 'utils/firebase/analytics';

interface Props {
  handleLinkClick: () => void;
  small?: boolean;
  className?: string;
}

const Navigation: React.FC<Props> = ({ handleLinkClick, small = false, className }) => {
  const isTransparent = useIsHeaderTransparent();

  const navItems = useHeaderItems();
  const items = navItems;

  return (
    <nav
      className={clsx(
        'border-y border-neutral-900 dark:border-white lg:border-0',
        {
          ['border-white lg:border-inherit']: isTransparent
        },
        className
      )}
    >
      <ul
        className={clsx(
          'flex flex-col lg:flex-row lg:items-center lg:gap-2 divide-y divide-neutral-700 dark:divide-neutral-700 lg:divide-y-0',
          {
            ['divide-neutral-700 lg:divide-inherit']: isTransparent
          }
        )}
      >
        {items.map(({ section, label, link, analyticEvent, items }) => (
          <NavigationItem
            key={label}
            section={section}
            analyticEvent={analyticEvent as ANALYTIC_EVENTS}
            label={label}
            items={items}
            link={link}
            small={small}
            handleLinkClick={handleLinkClick}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
