import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { useTranslation } from 'next-i18next';
import { X } from 'lucide-react';
import { cx } from 'class-variance-authority';
import clsx from 'clsx';
import { inter } from 'pages/_app';

const UserMenuDialog = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Root>
>(({ children, ...props }, ref) => {
  const { t } = useTranslation();
  const { onOpenChange, open } = props || {};

  const handleClickOutside = () => {
    if (onOpenChange) {
      onOpenChange(false);
    }
  };

  return (
    <DialogPrimitive.Root {...props}>
      <DialogPrimitive.Portal>
        <div
          className={clsx(
            'UserMenuDialog fixed inset-0 z-50 flex items-center justify-center',
            inter.className
          )}
        >
          {/* overlay start */}
          <div
            onClick={handleClickOutside}
            ref={ref}
            className={cx(
              'fixed inset-0 z-50 bg-black/20 transition-all duration-100',
              open ? 'fade-in' : 'animate-out'
            )}
          />
          {/* overlay end */}
          <div
            ref={ref}
            className={cx(
              'fixed z-50 top-0 bottom-0 right-0 w-full sm:w-[max-content] sm:max-w-[50%] md:max-w-[50%] animate-in bg-white dark:bg-neutral-900 md:border-l md:border-l-neutral-900 md:dark:border-l-white',
              open ? 'fade-in slide-in-from-right' : ''
            )}
          >
            <DialogPrimitive.Close className="absolute top-4 right-4 p-2 default-button text-neutral-900 disabled:text-neutral-400 bg-neutral-100 hover:bg-neutral-50 focus:bg-neutral-50 hover:disabled:bg-neutral-100">
              <X strokeWidth={1.5} size={20} />
              <span className="sr-only">{t('general.close')}</span>
            </DialogPrimitive.Close>
            {children}
          </div>
        </div>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
});
UserMenuDialog.displayName = 'UserMenuDialog';

export default UserMenuDialog;
