import * as React from 'react';
import { getItemLink } from 'utils/featuredLIst';
import Link from 'components/Link';
import Paragraph from 'components/text/Paragraph';
import { useTranslation } from 'next-i18next';
import { GlobalSearchItem } from 'components/GlobalSearch/GlobalSearch.utils';
import { Search } from 'lucide-react';
import Heading from 'components/text/Heading';
import clsx from 'clsx';
import { useIsHeaderTransparent } from 'components/Header/Header.utils';
import useScrollDirection from 'hooks/useScroll';

interface Props {
  items: Array<GlobalSearchItem>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  value: string;
}

const GlobalSearchItemsHeader: React.FC<Props> = ({ items, isOpen = false, setIsOpen, value }) => {
  const { t } = useTranslation();
  const isTransparent = useIsHeaderTransparent();
  const { towardsTop } = useScrollDirection();

  const handleClick = React.useCallback(e => {
    e.preventDefault();
  }, []);

  React.useEffect(() => {
    if (!towardsTop && isOpen) {
      setIsOpen(false);
    }
  });

  if (!items?.length || !isOpen) {
    return null;
  }

  return (
    <div
      onMouseDown={handleClick}
      className={clsx(
        'GlobalSearchItemsHeader absolute bg-white w-full top-12 rounded-2xl md:rounded-3xl p-6 z-30 opacity-0',
        {
          ['!opacity-100 delay-150']: isOpen,
          ['border border-neutral-900']: !isTransparent
        }
      )}
    >
      {!value && (
        <Heading variant="h4" className="mb-3 !text-subtitle">
          {t('globalSearch.title')}
        </Heading>
      )}
      <div className="flex flex-col gap-6 md:gap-3 max-h-[200px] overflow-auto">
        {items.map((item, index) => (
          <Link
            key={`search-suggestion-${item.slug}-${index}`}
            href={getItemLink(item?.entityType, item?.slug) || ''}
            className="flex gap-3 md:gap-6 items-center hover:!text-neutral-500"
            onClick={() => setIsOpen(false)}
          >
            <Search strokeWidth={1.5} size={16} className="min-w-4" />
            <Paragraph
              className="hover:!text-inherit text-ellipsis overflow-hidden whitespace-nowrap text-sm md:text-base"
              variant="body1"
            >
              <span className="font-semibold">{item.businessName}</span> {item.label}
            </Paragraph>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default GlobalSearchItemsHeader;
