import * as React from 'react';
import clsx from 'clsx';
import Button from 'components/Button';
import Navigation from 'components/Navigation/Navigation';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { authUserSelectors } from 'contexts/authUser';
import { useAuth } from 'contexts/authUser/store';
import {
  getShowGlobalSearch,
  shouldRenderLoginAndRegister,
  useIsHeaderTransparent
} from './Header.utils';
import View from 'components/View';
import Link from 'components/Link';
import dynamic from 'next/dynamic';
import { Menu, X } from 'lucide-react';
import useScrollDirection from 'hooks/useScroll';
import UserMenu from 'components/UserMenu/UserMenu';
import MiniCart from 'components/MiniCart/MiniCart';
import { APP_ENTRY_ROUTE, routes } from 'utils/routes';
import GlobalSearchHeader from 'components/GlobalSearch/GlobalSearchHeader';
import HeaderBanner from './HeaderBanner';
import LanguageSwitcher from 'components/LanguageSwitcher';

const PoinzLogo = dynamic(() => import('components/Svg/PoinzMinmal'));

const Header: React.FC = () => {
  const router = useRouter();

  const { t } = useTranslation();
  const renderLoginAndRegisterButtons = shouldRenderLoginAndRegister(router.pathname);
  const { towardsTop } = useScrollDirection();
  const { isLoggedIn } = useAuth(state => ({
    isLoggedIn: authUserSelectors.isLoggedIn(state)
  }));

  const [showNavMenu, setShowNavMenu] = React.useState(false);
  const [isGlobalSearchOpen, setIsGlobalSearchOpen] = React.useState(false);
  const isTransparent = useIsHeaderTransparent();
  const [headerTopOffset, setHeaderTopOffset] = React.useState(0);

  const handleSetIsGlobalSearchOpen = React.useCallback((open: boolean) => {
    setIsGlobalSearchOpen(open);
    if (open) {
      setShowNavMenu(false);
    }
  }, []);

  const showAuthButtons = React.useMemo(
    () => renderLoginAndRegisterButtons && !isLoggedIn,
    [isLoggedIn, renderLoginAndRegisterButtons]
  );

  const showGlobalSearch = React.useMemo(
    () => getShowGlobalSearch({ pathname: router.pathname }),
    [router.pathname]
  );

  const handleBannerClose = () => {
    setHeaderTopOffset(0);
  };

  // adjust header offset when banner is open/closed
  const onRefChange = React.useCallback(node => {
    if (node === null) {
      setHeaderTopOffset(0);
    } else {
      setHeaderTopOffset(node.clientHeight);
    }
  }, []);

  const toggleNavMenu = (forceClose?: boolean) => {
    document.body.classList.remove('overflow-hidden');
    setShowNavMenu(currentState => {
      const nextState = !(forceClose || currentState);

      nextState
        ? document.body.classList.add('overflow-hidden')
        : document.body.classList.remove('overflow-hidden');
      return nextState;
    });
  };

  const renderAuthButtons = () => (
    <>
      <Link
        href={{
          pathname: routes.auth.login(),
          query: { redirectTo: router.asPath, ...router.query }
        }}
        passHref
        legacyBehavior
      >
        <Button
          className="w-full lg:shrink-0"
          variant="primary-badge"
          size="badge"
          onClick={() => toggleNavMenu(true)}
        >
          {t('general.header.signIn')}
        </Button>
      </Link>
      <Link
        href={{
          pathname: routes.auth.register(),
          query: { redirectTo: router.asPath, ...router.query }
        }}
        passHref
        legacyBehavior
      >
        <Button
          className="w-full lg:shrink-0 bg-neutral-700 lg:bg-neutral-900"
          variant="secondary-badge"
          size="badge"
          onClick={() => toggleNavMenu(true)}
        >
          {t('general.header.signUp')}
        </Button>
      </Link>
    </>
  );

  // TODO try to optimize JSX some sections could be extract into component or render functions
  return (
    <>
      <HeaderBanner ref={onRefChange} showNavMenu={showNavMenu} onClose={handleBannerClose} />
      <header
        className={clsx(
          'Header z-50 bg-white dark:bg-neutral-900  transition-translate duration-200 !font-normal',
          {
            [`-translate-y-full`]: !towardsTop,
            ['!bg-transparent']: isTransparent
          }
        )}
      >
        <div className="relative">
          <View
            className={clsx(
              'flex flex-1 items-center justify-between lg:justify-start gap-4 py-6 lg:py-4 h-[84px]',
              {
                ['bg-white']: !isTransparent && showNavMenu,
                ['bg-neutral-900']: isTransparent && showNavMenu
              }
            )}
          >
            <Link
              href={APP_ENTRY_ROUTE}
              onClick={() => toggleNavMenu(true)}
              aria-label="poinz logo - home"
            >
              <PoinzLogo
                className={clsx('w-[100px] text-black dark:text-white', {
                  ['!text-white']: isTransparent
                })}
              />
            </Link>
            {/* NAVIGATION START (nav links, menu...) */}
            {!isGlobalSearchOpen && (
              <div
                className={clsx(
                  'overflow-y-auto lg:overflow-y-visible items-center gap-1 lg:gap-2 justify-between fixed top-[84px] w-full lg:w-fit left-0 bottom-0 lg:h-auto bg-white dark:bg-neutral-900 lg:flex lg:static lg:bg-transparent',
                  { ['hidden']: !showNavMenu, ['!bg-neutral-900 lg:!bg-inherit']: isTransparent }
                )}
              >
                {/* large screens */}
                <Navigation
                  className="hidden lg:block"
                  handleLinkClick={() => toggleNavMenu(true)}
                />
                {/* small screens */}
                <Navigation
                  className="block lg:hidden"
                  small
                  handleLinkClick={() => toggleNavMenu(true)}
                />

                <div
                  className={clsx(
                    'lg:hidden flex items-center gap-3 lg:gap-2 border-b border-b-neutral-900 dark:border-b-white lg:border-b-0 pl-3 pr-6 py-3 lg:p-0',
                    {
                      ['border-b-white']: isTransparent
                    }
                  )}
                >
                  <LanguageSwitcher
                    btnClass={clsx('hover:bg-neutral-50 focus:bg-neutral-100 px-3', {
                      ['hover:!bg-white/20 focus:bg-transparent !bg-transparent text-white']:
                        isTransparent
                    })}
                    contentClass={clsx(
                      isTransparent && '!bg-transparent backdrop-blur-[6px] border-white text-white'
                    )}
                    dropdownItemClass={clsx(isTransparent && 'hover:!text-neutral-200')}
                  />
                  {showAuthButtons && renderAuthButtons()}
                </div>
              </div>
            )}
            {/* NAVIGATION END (nav links, menu...) */}

            {/* ACTION BUTTONS (login logout user shoppingCart...) */}
            <div
              className={clsx(
                'flex items-center gap-2 p-0 justify-end flex-1',
                // 124px represent logo 100px + gap 24 so it's centered
                isGlobalSearchOpen ? 'justify-center mr-[124px]' : 'justify-end'
              )}
            >
              {!isGlobalSearchOpen && (
                <LanguageSwitcher
                  btnClass={clsx(
                    'hover:!bg-neutral-100 data-[state=open]:bg-neutral-100 focus:bg-transparent hidden lg:block px-3',
                    {
                      ['hover:!bg-white/20 hover:backdrop-blur-[6px] focus:bg-transparent text-white data-[state=open]:bg-white/20 data-[sate=open]:backdrop-blur-[6px]']:
                        isTransparent
                    }
                  )}
                  contentClass={clsx({
                    ['!bg-transparent !bg-white/20 backdrop-blur-[6px] border-white text-white']:
                      isTransparent
                  })}
                  dropdownItemClass={clsx({ ['hover:!text-neutral-200']: isTransparent })}
                />
              )}
              {showGlobalSearch && (
                <GlobalSearchHeader onOpenChange={handleSetIsGlobalSearchOpen} />
              )}

              {/* {process.env.NODE_ENV === 'development' && <ToggleTheme />} */}
              {showAuthButtons && !showNavMenu && !isGlobalSearchOpen && (
                <>
                  <Link
                    href={{
                      pathname: routes.auth.login(),
                      query: { redirectTo: router.asPath }
                    }}
                    passHref
                    legacyBehavior
                  >
                    <Button
                      className={clsx('lg:shrink-0 lg:hover:!bg-neutral-50', {
                        ['lg:!bg-white']: isTransparent
                      })}
                      variant="primary-badge"
                      size="badge"
                      onClick={() => toggleNavMenu(true)}
                    >
                      {t('general.header.signIn')}
                    </Button>
                  </Link>
                  <Link
                    href={{
                      pathname: routes.auth.register(),
                      query: { redirectTo: router.asPath }
                    }}
                    passHref
                    legacyBehavior
                  >
                    <Button
                      className={clsx('hidden lg:inline-flex lg:shrink-0', {
                        ['bg-white/20 hover:bg-white/40 backdrop-blur-[6px]']: isTransparent
                      })}
                      variant="secondary-badge"
                      size="badge"
                      onClick={() => toggleNavMenu(true)}
                    >
                      {t('general.header.signUp')}
                    </Button>
                  </Link>
                </>
              )}
              {isLoggedIn && !isGlobalSearchOpen && (
                <>
                  <UserMenu onOpen={() => toggleNavMenu(true)} />
                  <MiniCart actionButtonCallback={() => toggleNavMenu(true)} />
                </>
              )}
              {!isGlobalSearchOpen && (
                <Button
                  className="lg:hidden !px-2 !bg-neutral-100 hover:!bg-neutral-50"
                  size="badge"
                  onClick={() => toggleNavMenu()}
                >
                  {showNavMenu ? (
                    <X strokeWidth={1.5} size={20} />
                  ) : (
                    <Menu strokeWidth={1.5} size={20} />
                  )}
                </Button>
              )}
            </div>
            {/* ACTION BUTTONS END (login logout user shoppingCart...) */}
          </View>
        </div>
      </header>
    </>
  );
};

export default Header;
